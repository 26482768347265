<template>
  <b-modal
    id="modal-import"
    :title="!importSuccess ? 'Thêm file' : 'Import thành công'"
    :size="!importSuccess ? 'md' : 'sm'"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <div v-if="!importSuccess" class="py-4">
          <label for="">Import file</label>
          <div class="wrap__file mb-1">
            <span class="file__name">
              {{ file ? fileName : 'file import.xlsx' }}
            </span>
            <b-icon-upload
              scale="1.5"
              class="cursor-pointer"
              @click="$refs.fileUpload.click()"
            ></b-icon-upload>
            <input
              v-show="false"
              type="file"
              name=""
              id="file-upload"
              ref="fileUpload"
              accept=".xlsx, .xls"
              @change="onUploadFile"
            />
          </div>
          <span v-if="showFileSizeError" class="text-danger"
            >Kích thước file import tối đa nhỏ hơn 1MB</span
          >
        </div>
        <div
          v-if="!importSuccess"
          class="d-flex justify-content-end align-items-center mt-4"
        >
          <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
            Hủy
          </b-button>
          <b-button
            class="btn btn-success ml-3"
            type="submit"
            :disabled="disableBtnImport"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Import
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { EXCEL_FILE_TYPE } from '@/core/plugins/constants';
export default {
  name: 'ModalImport',
  data() {
    return {
      importSuccess: false,
      file: null,
      showFileSizeError: false,
      loading: false,
    };
  },
  computed: {
    fileName() {
      return this.file && this.file.name;
    },
    disableBtnImport() {
      return !this.file || this.showFileSizeError;
    },
  },
  watch: {},
  methods: {
    resetModal() {
      this.importSuccess && this.$emit('import-success');
      this.file = null;
      this.showFileSizeError = false;
      this.importSuccess = false;
      this.$emit('reset');
    },
    onClickCancelButton() {
      this.$bvModal.hide('modal-import');
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('importFile', this.file);
        const { meta, data, error } = await this.$api.post(
          'Voucher/ImportVoucher',
          formData,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.importSuccess = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    getErrorMessages(text) {
      if (!text) return [];
      const errorMessages = text.split('\n');
      errorMessages.pop();
      return errorMessages;
    },
    onUploadFile(event) {
      const file = event.target.files[0];
      // validate file type
      if (!EXCEL_FILE_TYPE.includes(file.type)) {
        return (this.$refs.fileUpload.value = null);
      }

      if (file.size > 1024000) {
        return (this.showFileSizeError = true);
      }

      this.file = file;
      this.showFileSizeError = false;
    },
    getStatus(status) {
      if (status) return 'Thành công';
      return 'Lỗi';
    },
    getClassByStatus(status) {
      if (status) return 'active';
      return 'inactive';
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap__file {
  padding: 10px 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap__icon {
  background: #e4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  width: 110px;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }
}
</style>

<style lang="scss">
#modal-import {
  .modal-dialog.modal-xl {
    max-width: 1500px;
  }
}
</style>
